import Validation from '@/core/services/Validation';
import { parseDate } from '@/core/services/DateUtil';

interface ComponentProperties {
  type: string;
  value: string;
}

export class HardwareComponent {
  id: number;
  name: string;
  brand: string;
  date: string;
  link: string;
  type: string;
  properties: ComponentProperties[];

  public validation: Validation;

  constructor(data: HardwareComponent) {
    this.id = data.id;
    this.name = data.name;
    this.brand = data.brand;
    this.date = data.date;
    this.link = data.link;
    this.type = data.type;
    this.properties = data.properties || [];

    this.validation = new Validation({
      name: 'required',
      brand: 'required',
      date: 'required',
      type: 'required',
    });
  }

  get key() {
    return `${this.name}_${this.date}`;
  }

  get url() {
    return `${this.name.replace(/\W+/g, '-').replace('--', '').toLowerCase()}-${
      this.year
    }`;
  }

  get year() {
    const d = parseDate(this.date);
    return d.getUTCFullYear();
  }

  get fullName(): string {
    if (this.name.indexOf(this.brand) !== -1) {
      return this.name;
    }
    return `${this.brand} ${this.name}`;
  }

  data(): any {
    return {
      id: this.id,
      name: this.name,
      brand: this.brand,
      date: this.date,
      link: this.link,
      type: this.type,
      properties: this.properties,
    };
  }

  isValid(field?: string): boolean {
    return this.validation.isValid(this, field);
  }

  getPropertyValue(type: string): string {
    return this.properties.find((p) => p.type === type)?.value || '';
  }
}

interface HardwarePurchaseItem {
  componentId?: number;
  description?: string;
  count: number;
  price: string;
}

export class HardwarePurchase {
  id: number;
  name: string;
  store: number;
  total_price: number;
  date: string;
  items: HardwarePurchaseItem[];
  public validation: Validation;

  constructor(data: HardwarePurchase) {
    this.id = data.id;
    this.name = data.name;
    this.store = data.store;
    this.total_price = data.total_price;
    this.date = data.date;
    this.items = data.items;

    this.validation = new Validation({
      name: 'required',
      total_price: 'required',
      date: 'required, date',
      store: 'required',
    });
  }

  get key() {
    return `${this.name}_${this.date}`;
  }

  get url() {
    return `${this.name.replace(/\W+/g, '-').replace('--', '').toLowerCase()}-${
      this.year
    }`;
  }

  get price(): number {
    return this.total_price;
  }

  get year() {
    const d = parseDate(this.date);
    return d.getUTCFullYear();
  }

  data(): any {
    return {
      id: this.id,
      name: this.name,
      store: this.store,
      total_price: this.total_price,
      date: this.date,
      items: this.items,
    };
  }

  isValid(field?: string): boolean {
    return this.validation.isValid(this, field);
  }
}

export const defaultComponent = new HardwareComponent({
  id: -1,
  name: '',
  brand: '',
  price: 0,
  date: '',
  link: '',
  type: 'unknown',
  properties: [],
} as any);

export const defaultPurchase = new HardwarePurchase({
  id: -1,
  name: '',
  store: '',
  price: 0,
  date: '',
  items: [],
} as any);

export interface TypeLabel {
  label: string;
  value: string;
}

export interface HardwareConfiguration {
  brands: string[];
  componentTypes: TypeLabel[];
  propertyTypes: TypeLabel[];
}
