import Validation from '@/core/services/Validation';

export class System {
  public id: number;
  public slug: string;
  public name: string;
  public image: string;
  public isCurrent: boolean;
  public case: number;
  public motherboard: number;
  public powersupply: number;
  public cpu: number;
  public cpucooler: number;
  public memory: number;
  public gpu: number;
  public monitor: number;
  public otherComponents: number[];
  public validation: Validation;

  constructor(system: System) {
    this.id = system.id;
    this.slug = system.slug;
    this.name = system.name;
    this.image = system.image;
    this.isCurrent = system.isCurrent;
    this.case = system.case;
    this.motherboard = system.motherboard;
    this.powersupply = system.powersupply;
    this.cpu = system.cpu;
    this.cpucooler = system.cpucooler;
    this.memory = system.memory;
    this.gpu = system.gpu;
    this.monitor = system.monitor;
    this.otherComponents = system.otherComponents;
    this.validation = system.validation;

    this.validation = new Validation({
      name: 'required',
    });
  }

  isValid(field?: string): boolean {
    return this.validation.isValid(this, field);
  }
}

export const defaultSystem = new System({
  id: -1,
  slug: '',
  name: '',
  image: '',
  isCurrent: false,
  case: -1,
  motherboard: -1,
  powersupply: -1,
  cpu: -1,
  cpucooler: -1,
  memory: -1,
  gpu: -1,
  monitor: -1,
  otherComponents: [],
} as any);
