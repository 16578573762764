<template>
  <div class="sg-icon">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 130 96"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs />
      <g transform="translate(1.000000, 1.000000)" stroke="#000000">
        <path
          d="M51.1331403,2.42394015 C49.7223142,1.2548825 49.4215844,0.166593426 48.0495371,0.166593426 C35.5885988,0.166593426 22.3848921,1.72545483 18.1070554,5.24567302 C17.4285945,5.5551194 17.0302738,6.66038603 16.8013828,8.26395695 C17.0621691,7.96913219 17.3054459,7.76009316 17.5274314,7.64962594 C21.5614173,4.02779765 37.2044888,2.42394015 48.9551122,2.42394015 C49.5561694,2.42394015 50.2914369,2.42394015 51.1331403,2.42394015 Z M76.6457883,2.42394015 C78.0342151,1.2548825 78.342908,0.166593426 79.8222515,0.166593426 C92.2831898,0.166593426 105.486896,1.72545483 109.764733,5.24567302 C110.443194,5.5551194 110.841515,6.66038603 111.070406,8.26395695 C110.809619,7.96913219 110.566343,7.76009316 110.344357,7.64962594 C106.310371,4.02779765 90.6672998,2.42394015 78.9166764,2.42394015 C78.2936171,2.42394015 77.5263419,2.42394015 76.6457883,2.42394015 Z"
        />
        <path
          d="M48.9551122,2.42394015 C37.2044888,2.42394015 21.5614173,4.02779765 17.5274314,7.64962594 C14.6324165,9.0902793 8.11621144,27.2959842 6.36653924,33.9061024 C4.61686705,40.5162206 -0.0238762891,63.9620005 0.0187032419,69.9825436 C0.0612827729,76.0030867 1.24438903,87.1471322 11.2394015,91.5897756 C17.7532931,93.9109806 20.7718204,94.4650873 23.2144638,93.0274314 C25.6571072,91.5897756 32.4994356,79.5789668 33.9134006,76.7859494 C35.3273657,73.9929319 37.1184539,68.4663342 40.4389027,66.3703242 C42.2421405,64.987778 46.3316708,65.3474229 48.9551122,65.2389441 C50.23053,65.1862058 77.6412586,65.1862058 78.9166764,65.2389441 C81.5401178,65.3474229 85.6296481,64.987778 87.4328859,66.3703242 C90.7533348,68.4663342 92.5444229,73.9929319 93.958388,76.7859494 C95.3723531,79.5789668 102.214681,91.5897756 104.657325,93.0274314 C107.099968,94.4650873 110.118495,93.9109806 116.632387,91.5897756 C126.6274,87.1471322 127.810506,76.0030867 127.853085,69.9825436 C127.895665,63.9620005 123.254922,40.5162206 121.505249,33.9061024 C119.755577,27.2959842 113.239372,9.0902793 110.344357,7.64962594 C106.310371,4.02779765 90.6672998,2.42394015 78.9166764,2.42394015 C73.107865,2.42394015 54.7639236,2.42394015 48.9551122,2.42394015 Z"
        />
        <path
          d="M84.7491292,58.8375692 C84.3727347,59.0503706 82.8690241,59.2711879 80.2379974,59.5000211 L48.0696042,59.5000211 C45.4385776,59.2711879 43.9348669,59.0503706 43.5584724,58.8375692 C43.1825941,58.6250598 38.1731029,52.874637 28.5299988,42.5899963 C37.290326,42.5267076 43.6999969,35.9074122 43.6999969,27.1321696 C43.6999969,21.7613561 40.7194707,17.0101599 36.6525839,14.1174872 C40.0099788,13.6257352 45.2257515,13.3798592 50.2999021,13.3798592 L78.0076995,13.3798592 C82.9565761,13.3798592 88.0889361,13.6137444 91.4047794,14.0815147 C87.3092077,16.9706753 84,21.7390332 84,27.1321696 C84,35.9466893 90.7797927,42.5899963 99.5943124,42.5899963 C90.1346974,52.8740036 85.1250149,58.6250556 84.7491292,58.8375692 Z"
          stroke-width="0.5"
        />
        <path
          d="M9.91877843,26.4801218 C8.17426,35.7837548 9.87425955,46.5242683 10.4798383,51.9425981 C11.061847,57.150038 15.0282579,70.3767672 18.003007,76.415195 C22.9162902,86.388643 26.4433657,89.2583604 26.3986603,89.3225317 C25.1138668,91.1667586 23.9595055,92.5889255 23.2144638,93.0274314 C20.7718204,94.4650873 17.7532931,93.9109806 11.2394015,91.5897756 C1.24438903,87.1471322 0.0612827729,76.0030867 0.0187032419,69.9825436 C-0.0238762891,63.9620005 4.61686705,40.5162206 6.36653924,33.9061024 C7.03008741,31.3992718 8.37917765,27.224717 9.95630968,22.9293415 C12.5378269,15.8984872 11.1310688,20.0148964 9.91877843,26.4801218 Z"
          stroke-width="0.5"
        />
        <path
          d="M117.986537,26.3746525 C119.731055,35.6782855 118.031055,46.418799 117.425477,51.8371288 C116.843468,57.0445687 112.877057,70.2712979 109.902308,76.3097258 C104.989025,86.2831737 101.461949,89.1528912 101.506655,89.2170624 C102.791448,91.0612893 103.945809,92.4834562 104.690851,92.9219621 C107.133494,94.359618 110.152022,93.8055113 116.665913,91.4843063 C126.660926,87.0416629 127.844032,75.8976175 127.886612,69.8770743 C127.929191,63.8565312 123.288448,40.4107513 121.538776,33.8006331 C120.875228,31.2938025 119.526137,27.1192477 117.949005,22.8238722 C115.367488,15.7930179 116.774246,19.9094271 117.986537,26.3746525 Z"
          stroke-width="0.5"
        />
        <circle cx="28.4089776" cy="27.1321696" r="15.4600998" />
        <circle cx="99.5910224" cy="27.1321696" r="15.4600998" />
        <circle
          stroke-width="0.5"
          cx="48.5187032"
          cy="45.9650873"
          r="9.91708229"
        />
        <circle cx="48.5187032" cy="45.9650873" r="7.79925187" />
        <circle
          stroke-width="0.5"
          cx="72.2992519"
          cy="46.1246883"
          r="3.42082294"
        />
        <circle
          stroke-width="0.5"
          cx="79.6408978"
          cy="38.7830424"
          r="3.42082294"
        />
        <circle
          stroke-width="0.5"
          cx="86.9825436"
          cy="46.1246883"
          r="3.42082294"
        />
        <circle
          stroke-width="0.5"
          cx="79.6408978"
          cy="53.4663342"
          r="3.42082294"
        />
        <rect
          stroke-width="0.5"
          x="49.4071072"
          y="24.1901496"
          width="7.79925187"
          height="3.96882793"
          rx="1.98441397"
        />
        <circle
          stroke-width="0.5"
          cx="64.159601"
          cy="24.5785536"
          r="4.85723192"
        />
        <rect
          stroke-width="0.5"
          x="71.1128429"
          y="24.1901496"
          width="7.79925187"
          height="3.96882793"
          rx="1.98441397"
        />
      </g>
    </svg>
  </div>
</template>
