import { createApp } from 'vue';
import SoesahGamingApp from './SoesahGamingApp.vue';
import router from './router';
import { store } from './store';
import AutoFocus from './directives/auto-focus';

const app = createApp(SoesahGamingApp).use(router).use(store);
app.directive('auto-focus', AutoFocus);

app.mount('#app');
