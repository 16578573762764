interface RatingData {
  graphics: number;
  lasting_appeal: number;
  replayability: number;
  gameplay: number;
  depth: number;
}

export const defaultRating: Rating = {
  graphics: 0,
  lasting_appeal: 0,
  replayability: 0,
  gameplay: 0,
  depth: 0,
};

export class Rating {
  public graphics: number;
  public lasting_appeal: number;
  public replayability: number;
  public gameplay: number;
  public depth: number;

  constructor({
    graphics = 0,
    lasting_appeal = 0,
    replayability = 0,
    gameplay = 0,
    depth = 0,
  }: RatingData) {
    this.graphics = graphics;
    this.lasting_appeal = lasting_appeal;
    this.replayability = replayability;
    this.gameplay = gameplay;
    this.depth = depth;
  }
}
