export default class CurrencyUtil {
  static parseCurrency(str: number | string): number {
    // turn it into a string
    if (typeof str !== 'string') {
      str = str + '';
    }

    let index = str.indexOf('€');

    // remove euro sign
    if (index !== -1) {
      str = str.substring(index + 1);
    }

    return parseFloat(str);
  }

  static correctValue(value: number | string): string {
    let isNotFloat = true;

    // turn it into a string
    if (typeof value !== 'string') {
      value = value + '';
    }

    // find the dot
    isNotFloat = value.indexOf('.') === -1;

    if (value.length === 2 && value.substring(0, 1) === '0' && isNotFloat) {
      value += '0';
    }
    if (value.length > 2 && value.substring(0, 1) === '0' && isNotFloat) {
      value = parseInt(value, 10) / 100;
    }
    return value ? CurrencyUtil.parseCurrency(value).toFixed(2) : `${value}`;
  }

  static formatPrice(value: string | number) {
    value = CurrencyUtil.correctValue(value);

    return value !== ''
      ? `€ ${CurrencyUtil.parseCurrency(value).toFixed(2)}`
      : '';
  }
}
