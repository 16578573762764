import {
  BaseService,
  DataResponse,
  SuccessResponse,
} from '@/core/services/BaseService';
import { Game, GameData } from '@/models/game.model';

class GameService extends BaseService {
  public items: Game[] = [];
  private url: string = '/api/games';
  private imageUrl: string = '/api/images';

  constructor() {
    super();
  }

  public async load(): Promise<DataResponse<Game[]>> {
    if (this.items.length) {
      return {
        status: true,
        message: 'Data already loaded',
        data: this.items,
      };
    } else {
      const response = await this.$http
        .get(this.url)
        .catch((error) => error.response);

      const { status, message } = this.parseResponse(response);

      this.items = status
        ? response.data.data.map((game: GameData) => new Game(game))
        : [];

      return {
        status,
        message,
        data: this.items,
      };
    }
  }

  public async update(
    slug: string,
    data: FormData,
  ): Promise<DataResponse<Game[]>> {
    const response = await this.$http
      .put<Game>(`${this.url}/${slug}`, data)
      .catch((error) => error.response);

    const { status, message } = this.parseResponse(response);
    const game = new Game(response.data.data);
    this.items = [...this.items.filter((g) => g.url !== game.url), game];

    return {
      status,
      message,
      data: this.items,
    };
  }

  public async removeGameImage(filename: string): Promise<SuccessResponse> {
    return await this.$http
      .delete(`${this.imageUrl}/${filename}`)
      .catch((error) => error.response);
  }

  public async save(data: FormData): Promise<DataResponse<Game[]>> {
    const response = await this.$http
      .post<Game>(this.url, data)
      .catch((error) => error.response);

    const { status, message } = this.parseResponse(response);
    const game = new Game(response.data.data);

    this.items = [...this.items, game];

    return {
      status,
      message,
      data: this.items,
    };
  }
}

export default new GameService();
