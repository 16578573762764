import Validation from '@/core/services/Validation';
import { LoginData } from '@/services/AuthService';

export default class User {
  public name: string;
  public password: string;
  public validation: Validation;

  constructor({ name = '', password = '' }) {
    this.name = name;
    this.password = password;

    this.validation = new Validation({
      name: 'required',
      password: 'required',
    });
  }

  data(): LoginData {
    return {
      name: this.name,
      password: this.password,
    };
  }

  isValid(field?: string) {
    return this.validation.isValid(this, field);
  }
}
