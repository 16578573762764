import {
  formatDate,
  getMonday,
  MILLISECONDS_IN_DAY,
} from '@/core/services/DateUtil';
import { format, getISOWeek, startOfISOWeek } from 'date-fns';

export interface ScheduleDay {
  date: string;
  played: boolean;
  exercised: boolean;
  programmed: boolean;
  read: boolean;
  write: boolean;
  game?: string;
}

export interface ScheduleData {
  year: number;
  weekNumber: number;
  schedule: ScheduleDay[];
}

const PLAYABLE_DAYS = 3;

interface ScheduleInterface {
  year: number;
  weekNumber: number;
  schedule: ScheduleDay[];
}

export class Schedule {
  public year: number;
  public weekNumber: number;
  public schedule: ScheduleDay[];

  constructor(
    schedule: ScheduleData = { year: -1, weekNumber: -1, schedule: [] },
    date: string = getMonday(),
  ) {
    if (schedule.weekNumber === -1) {
      const d = new Date(date);
      this.year = d.getFullYear();
      this.weekNumber = getISOWeek(d);
      this.schedule = this.completeSchedule(schedule, date);
    } else {
      this.year = schedule.year;
      this.weekNumber = schedule.weekNumber;
      this.schedule = schedule.schedule;
    }
  }

  get date(): string {
    return this.schedule[0].date;
  }

  get daysLeft() {
    return (
      PLAYABLE_DAYS -
      this.schedule.filter(d => d.played).length +
      this.schedule.filter(
        d => d.exercised || d.programmed || d.read || d.write,
      ).length
    );
  }

  public updateDay(day: ScheduleDay) {
    this.schedule = this.schedule.map(d => {
      return d.date === day.date ? day : d;
    });
    return this;
  }

  private completeSchedule(
    schedule: ScheduleData,
    date: string,
  ): ScheduleDay[] {
    let d = new Date(date);

    const weekStart = startOfISOWeek(d);

    const days = new Array(7).fill(1).map((_, index) => {
      const date = new Date(weekStart.getTime() + index * MILLISECONDS_IN_DAY);
      const h = schedule.schedule.find(h => h.date === formatDate(date));
      return h
        ? h
        : {
            date: format(date, 'yyyy-MM-dd'),
            played: false,
            exercised: false,
            programmed: false,
            read: false,
            write: false,
          };
    });

    return days;
  }

  public data(): ScheduleInterface {
    return {
      year: this.year,
      weekNumber: this.weekNumber,
      schedule: this.schedule.filter(
        d => d.played || d.exercised || d.programmed || d.read || d.write,
      ),
    };
  }
}
