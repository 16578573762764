import {
  Wishlist,
  WishlistItem,
  WishlistItemData,
} from '@/models/wishlist.model';
import { BaseService, DataResponse } from '@/core/services/BaseService';

class WishlistService extends BaseService {
  public data!: Wishlist;
  private file: string = '/api/file/wishlist.json';

  constructor() {
    super();
  }

  public async load(): Promise<DataResponse<Wishlist>> {
    if (this.data) {
      return {
        status: true,
        message: 'Data already loaded',
        data: this.data,
      };
    } else {
      const response = await this.$http
        .get(this.file)
        .catch((error) => error.response);

      const { status, message } = this.parseResponse(response);

      if (status) {
        this.data = new Wishlist(
          response.data.data.map(
            (item: WishlistItemData) => new WishlistItem(item),
          ),
        );
      }

      return {
        status,
        message,
        data: this.data,
      };
    }
  }

  public async save(list: WishlistItem[]): Promise<DataResponse<Wishlist>> {
    const response = await this.$http
      .post(
        this.file,
        list.map((item) => item.data()),
      )
      .catch((error) => error.response);

    const { status, message } = this.parseResponse(response);

    return {
      status,
      message,
      data: new Wishlist(list),
    };
  }
}

export default new WishlistService();
