import StringUtil from '@/core/services/StringUtil';

export interface ValidationProps {
  [index: string]: string;
}

export interface ValidationMessages {
  [index: string]: string;
}

export default class Validation {
  private _props: ValidationProps;
  public messages: ValidationMessages = {};
  private validated: boolean = false;

  constructor(props: ValidationProps) {
    this._props = props;
  }

  updateValidated(propname: string) {
    if (this.validated) {
      return;
    }
    this.validated = !propname;
  }

  isValid(model: any, propname?: string): boolean {
    if (propname) {
      this.updateValidated(propname);
    }

    // don't validate individual props
    // until the model has been asked to validate by the form
    if (!this.validated && propname) {
      return true;
    }

    if (propname && this._props[propname]) {
      return this.validateProp(model, propname);
    } else if (!propname) {
      // validate all props
      return Object.keys(this._props).reduce(
        (valid: boolean, propname: string) =>
          valid && this.validateProp(model, propname),
        true,
      );
    } else {
      return true;
    }
  }

  validateProp(model: any, propname: string): boolean {
    const value = model[propname];

    if (value === null || value === undefined || value === '') {
      this.messages[propname] = `${StringUtil.capitalize(
        propname,
      )} is required`;
      return false;
    } else if (value) {
      // clean previous validation messages
      delete this.messages[propname];
    }

    return true;
  }
}
