import { BaseService } from '@/core/services/BaseService';

export interface LoginData {
  name: string;
  password: string;
}

interface AuthResponse {
  status: boolean;
  message: string;
}

export class AuthService extends BaseService {
  private loggedIn: boolean = false;
  private baseUrl: string = '/api';

  constructor() {
    super();
  }

  public async update(): Promise<AuthResponse> {
    if (this.loggedIn) {
      return {
        status: true,
        message: 'User is logged in',
      };
    } else {
      const response = await this.$http
        .get(`${this.baseUrl}/auth`)
        .catch(error => error.response);

      const { status, message } = this.parseResponse(response);

      this.loggedIn = status;
      return {
        status,
        message,
      };
    }
  }

  public async login(data: LoginData): Promise<AuthResponse> {
    if (this.loggedIn) {
      return {
        status: true,
        message: 'User is logged in',
      };
    }

    const response = await this.$http
      .post(`${this.baseUrl}/login`, data)
      .catch(error => error.response);

    const { status, message } = this.parseResponse(response);

    this.loggedIn = status;

    return {
      status,
      message,
    };
  }

  public async logout(): Promise<boolean> {
    const response = await this.$http
      .get(`${this.baseUrl}/logout`)
      .catch(error => error.response);

    const { status } = this.parseResponse(response);
    this.loggedIn = false;

    return status;
  }

  isLoggedIn() {
    return this.loggedIn;
  }
}

export default new AuthService();
