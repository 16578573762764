import { Store, StoreData } from '@/models/store.model';
import { BaseService, DataResponse } from '@/core/services/BaseService';

class StoreService extends BaseService {
  public data: Store[] = [];
  private url: string = '/api/stores';

  constructor() {
    super();
  }

  public async getList(): Promise<DataResponse<Store[]>> {
    const response = await this.$http
      .get<Store[]>(`${this.url}`)
      .catch((error) => error.response);

    const { status, message } = this.parseResponse(response);

    if (status) {
      this.data = response.data.data.map((d: StoreData) => new Store(d));
    }

    return {
      status,
      message,
      data: this.data,
    };
  }

  public async add(store: Store): Promise<DataResponse<Store>> {
    const response = await this.$http
      .post(`${this.url}`, store)
      .catch((error) => error.response);

    const { status, message } = this.parseResponse(response);

    return {
      status,
      message,
      data: store,
    };
  }

  public async update(store: Store): Promise<DataResponse<Store>> {
    const response = await this.$http
      .put(`${this.url}/${store.id}`, store)
      .catch((error) => error.response);

    const { status, message } = this.parseResponse(response);

    return {
      status,
      message,
      data: store,
    };
  }
}

export default new StoreService();
