import { BaseService, DataResponse } from '@/core/services/BaseService';
import {
  HardwareComponent,
  HardwareConfiguration,
  HardwarePurchase,
} from '@/models/hardware.model';
import { AxiosResponse } from 'axios';

class HardwareService extends BaseService {
  public components: HardwareComponent[] = [];
  public purchases: HardwarePurchase[] = [];
  private url: string = '/api/hardware';

  constructor() {
    super();
  }

  public async loadConfiguration(): Promise<
    DataResponse<HardwareConfiguration>
  > {
    const response = await this.$http
      .get<HardwareConfiguration>(`${this.url}/configuration`)
      .catch((error) => error.response);

    const { status, message } = this.parseResponse(response);

    return {
      status,
      message,
      data: response.data.data,
    };
  }

  public async loadComponents(): Promise<DataResponse<HardwareComponent[]>> {
    if (this.components.length) {
      return {
        status: true,
        message: 'Data already loaded',
        data: this.components,
      };
    } else {
      const response = await this.$http
        .get(`${this.url}/components`)
        .catch((error) => error.response);

      const { status, message } = this.parseResponse(response);

      this.components = status
        ? response.data.data.map(
            (hardware: HardwareComponent) => new HardwareComponent(hardware),
          )
        : [];

      return {
        status,
        message,
        data: this.components,
      };
    }
  }

  public async saveComponent(
    component: HardwareComponent,
  ): Promise<DataResponse<HardwareComponent>> {
    const response: AxiosResponse<DataResponse<HardwareComponent>> =
      await this.$http
        .post<HardwareComponent>(`${this.url}/components`, component)
        .catch((error) => error.response);

    const { status, message } = this.parseResponse(response);
    return {
      status,
      message,
      data: response.data.data,
    };
  }

  public async updateComponent(
    component: HardwareComponent,
  ): Promise<DataResponse<HardwareComponent>> {
    const response: AxiosResponse<DataResponse<HardwareComponent>> =
      await this.$http
        .put<HardwareComponent>(
          `${this.url}/components/${component.id}`,
          component,
        )
        .catch((error) => error.response);

    const { status, message } = this.parseResponse(response);

    return {
      status,
      message,
      data: response.data.data,
    };
  }

  public async loadPurchases(): Promise<DataResponse<HardwarePurchase[]>> {
    if (this.purchases.length) {
      return {
        status: true,
        message: 'Data already loaded',
        data: this.purchases,
      };
    } else {
      const response = await this.$http
        .get(`${this.url}/purchase`)
        .catch((error) => error.response);

      const { status, message } = this.parseResponse(response);

      this.purchases = status
        ? response.data.data.map(
            (hardware: HardwarePurchase) => new HardwarePurchase(hardware),
          )
        : [];

      return {
        status,
        message,
        data: this.purchases,
      };
    }
  }

  public async savePurchase(
    purchase: HardwarePurchase,
  ): Promise<DataResponse<HardwarePurchase>> {
    const response: AxiosResponse<DataResponse<HardwarePurchase>> =
      await this.$http
        .post<HardwarePurchase>(`${this.url}/purchase`, purchase)
        .catch((error) => error.response);

    const { status, message } = this.parseResponse(response);
    return {
      status,
      message,
      data: response.data.data,
    };
  }

  public async updatePurchase(
    purchase: HardwarePurchase,
  ): Promise<DataResponse<HardwarePurchase>> {
    const response: AxiosResponse<DataResponse<HardwarePurchase>> =
      await this.$http
        .put<HardwarePurchase>(`${this.url}/purchase/${purchase.id}`, purchase)
        .catch((error) => error.response);

    const { status, message } = this.parseResponse(response);

    return {
      status,
      message,
      data: response.data.data,
    };
  }
}

export default new HardwareService();
