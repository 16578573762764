import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/components/login/Login.vue';
import GameList from '@/components/game/GameList.vue';
import GameView from '@/components/game/GameView.vue';
import GameEdit from '@/components/game/GameEdit.vue';
import HardwareComponentList from '@/components/hardware/HardwareComponentList.vue';
import HardwareComponentView from '@/components/hardware/HardwareComponentView.vue';
import HardwarePurchaseEdit from '@/components/hardware/HardwarePurchaseEdit.vue';
import HardwareComponentEdit from '@/components/hardware/HardwareComponentEdit.vue';
import StoreList from '@/components/stores/StoreList.vue';
import StoreEdit from '@/components/stores/StoreEdit.vue';
import PurchaseView from '@/components/purchases/PurchaseView.vue';
import WishlistList from '@/components/wishlist/WishlistList.vue';
import WishlistEdit from '@/components/wishlist/WishlistEdit.vue';
import SystemView from '@/components/system/SystemView.vue';
import SystemEdit from '@/components/system/SystemEdit.vue';
import ScheduleView from '@/components/schedule/ScheduleView.vue';
import authService from './services/AuthService';

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: GameList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/game/add',
      component: GameEdit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/game/:title',
      component: GameView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/game/:title/edit',
      component: GameEdit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/system/:slug',
      component: SystemView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/system/:slug/edit',
      component: SystemEdit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/hardware',
      component: HardwareComponentList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/hardware/purchase/add',
      component: HardwarePurchaseEdit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/hardware/purchase/:title/edit',
      component: HardwarePurchaseEdit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/hardware/add',
      component: HardwareComponentEdit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/hardware/:title',
      component: HardwareComponentView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/hardware/:title/edit',
      component: HardwareComponentEdit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/stores',
      component: StoreList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/stores/add',
      component: StoreEdit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/stores/edit/:id',
      component: StoreEdit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      component: Login,
    },
    {
      path: '/logout',
      redirect: '/login',
    },
    {
      path: '/purchases/:year',
      component: PurchaseView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/wishlist',
      component: WishlistList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/wishlist/add',
      component: WishlistEdit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/wishlist/:title/edit',
      component: WishlistEdit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/schedule',
      component: ScheduleView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/schedule/:date',
      component: ScheduleView,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach(async (to, _, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    await authService.update();

    if (!authService.isLoggedIn()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
