import Validation from '@/core/services/Validation';

export enum StoreType {
  GameStore,
  HardwareStore,
  GenericStore,
}

export interface StoreData {
  id: number;
  name: string;
  website: string;
  type: StoreType;
}

export class Store {
  public id: number = -1;
  public name: string = '';
  public website: string = '';
  public type: StoreType = StoreType.GenericStore;
  public validation: Validation;

  constructor(
    data: StoreData = {
      id: -1,
      name: '',
      website: '',
      type: StoreType.GenericStore,
    },
  ) {
    Object.assign(this, data);
    this.validation = new Validation({
      name: 'required',
    });
  }

  isValid(field?: string): boolean {
    return this.validation.isValid(this, field);
  }

  public data(): StoreData {
    return {
      id: this.id,
      name: this.name,
      website: this.website,
      type: this.type,
    };
  }
}
