import { format, startOfISOWeek } from 'date-fns';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const HUMAN_DATE = 'ddd MMM Do';
export const DATE_FORMAT_NICE = 'MMM Do YYYY';

export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MILLISECONDS_IN_MINUTE =
  SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
export const MINUTES_IN_HOUR = 60;
export const MILLISECONDS_IN_HOUR = MINUTES_IN_HOUR * MILLISECONDS_IN_MINUTE;
export const HOURS_IN_DAY = 24;
export const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * HOURS_IN_DAY;
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_YEAR = 365;
export const days: string[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const parseDate = (date: string): Date => new Date(Date.parse(date));

export const formatDate = (d: Date = new Date()): string => {
  const month = `${d.getMonth() + 1}`;
  const day = `${d.getDate()}`;

  return `${d.getFullYear()}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export const formatDateNice = (d: Date = new Date()): string => {
  const day = d.getDate();
  const ordinal = getOrdinal(day);

  return `${d.toLocaleDateString('en-US', {
    month: 'short',
  })} ${day}${ordinal} ${d.getFullYear()}`;
};

export const formatDateNicer = (d: Date = new Date()): string => {
  const day = days[d.getDay()];
  return `${day} ${formatDateNice(d)}`;
};

export const getOrdinal = (day: number): string => {
  const mapping = {
    st: [1, 21, 31],
    nd: [2, 22],
    rd: [3, 23],
  };

  return Object.entries(mapping).reduce(
    (acc: string, [key, vals]: [string, number[]]): string => {
      return vals.includes(day) ? key : acc;
    },
    'th',
  );
};

export const getMonday = (d: Date = new Date()): string =>
  format(startOfISOWeek(d), 'yyyy-MM-dd');
