import CurrencyUtil from '@/core/services/CurrencyUtil';

import { formatDateNice, parseDate } from '@/core/services/DateUtil';
import { format } from 'date-fns';

export const formatDate = (date: string) => formatDateNice(parseDate(date));
export const formatDateWithDay = (date: string) =>
  format(new Date(date), 'EEEE MMM do yyyy');

export const formatPrice = (value: string | number) =>
  CurrencyUtil.formatPrice(value);
