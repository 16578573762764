import Validation from '@/core/services/Validation';
import Product from '@/models/product.model';
import { formatDate } from '@/core/services/DateUtil';

export enum PurchaseType {
  All,
  Game,
  Hardware,
}

export default class Purchase<T> {
  public store: any;
  public price: number;
  public date: string;
  public purchases: Product<T>[];
  public validation: Validation;

  constructor(
    { store, price, date, purchases } = {
      store: '',
      price: 0,
      date: formatDate(),
      purchases: [],
    },
  ) {
    this.store = store;
    this.price = price;
    this.date = date;
    this.purchases = purchases
      ? purchases
          .map((purchase) => new Product<T>(purchase))
          .sort((a: Product<T>, b: Product<T>) => (a.date < b.date ? -1 : 1))
      : [];

    this.validation = new Validation({
      store: 'required',
      price: 'required',
      date: 'required',
    });
  }

  addPurchase(purchase: Product<T>) {
    this.purchases.push(purchase);
  }

  removePurchase(index: number) {
    this.purchases.splice(index, 1);
  }

  data() {
    const data: any = {
      store: this.store,
      price: this.price,
      date: this.date,
      purchases: [],
    };

    if (this.purchases.length) {
      data.purchases = this.purchases.map((purchase) => purchase.data());
    }

    return data;
  }

  isValid(field: string): boolean {
    return this.validation.isValid(this, field);
  }

  get cost(): number {
    return this.price;
  }
}
