import Purchase from '@/models/purchase.model';
import Validation from '@/core/services/Validation';
import { parseDate } from '@/core/services/DateUtil';
import { defaultRating, Rating } from './rating.model';

export enum GameStore {
  Steam = 0,
  GOG = 12,
  Origin = 20,
  Kickstarter = 30,
  EVEOnline = 40,
  Other = 50,
  UPlay = 60,
  Epic = 70,
}

export enum ProductTypes {
  DLC,
  AddOn,
  ExpansionPass,
  Subscription,
  SeasonPass,
}

// control schemes
export const SCHEME_JOYSTICK = 'joystick';
export const SCHEME_CONTROLLER = 'controller';
export const SCHEME_MOUSE_AND_KEYBOARD = 'mouse_and_keyboard';

export interface GameData {
  name: string;
  store: GameStore;
  rating: Rating;
  website: string | null;
  developer: string | null;
  completed: boolean;
  review: string;
  hashtag: string | null;
  image: string | null;
}

export class Game extends Purchase<ProductTypes> {
  public name: string;
  public store: GameStore;
  public rating: Rating;
  public website: string | null;
  public developer: string | null;
  public completed: boolean;
  public review: string;
  public hashtag: string | null;
  public image: string | null;
  public validation: Validation;

  constructor(
    {
      name,
      store,
      rating,
      website,
      developer,
      completed,
      review,
      hashtag,
      image,
    }: GameData = {
      name: '',
      store: GameStore.Steam,
      rating: defaultRating,
      website: null,
      developer: null,
      completed: false,
      review: '',
      hashtag: null,
      image: null,
    },
  ) {
    super(arguments[0]);
    this.name = name;
    this.store = store;
    this.rating = new Rating(rating);
    this.website = website;
    this.developer = developer;
    this.completed = completed;
    this.review = review;
    // subtitle/tagname
    this.hashtag = hashtag;
    this.image = image;
    // control scheme
    // coop play support
    // type/tags
    // purchases (move original purchase in here? date, price, store)

    this.validation = new Validation({
      name: 'required',
      price: 'required',
      date: 'required, date',
      store: 'required',
    });
  }

  get cost() {
    const purchasesCost = this.purchases
      ? this.purchases.reduce((s, item) => s + item.cost, 0)
      : 0;
    return this.price + purchasesCost;
  }

  get IS_GAME() {
    return true;
  }

  get key() {
    return this.name + '_' + this.date;
  }

  get url() {
    return (
      this.name.replace(/\W+/g, '-').replace('--', '').toLowerCase() +
      '-' +
      this.year
    );
  }

  get year() {
    const d = parseDate(this.date);
    return d.getUTCFullYear();
  }

  get icon() {
    return `${GameStore[this.store]}`.toLowerCase();
  }

  public data(): GameData {
    return Object.assign(super.data(), {
      name: this.name,
      website: this.website,
      hashtag: this.hashtag,
      developer: this.developer,
      rating: this.rating,
      completed: this.completed,
      review: this.review,
      image: this.image,
    });
  }

  isValid(field?: string): boolean {
    return this.validation.isValid(this, field);
  }
}
