import Validation from '@/core/services/Validation';
import { parseDate } from '@/core/services/DateUtil';

export default class Product<T> {
  public name: string;
  public price: number;
  public date: string;
  public type: T;
  public validation: Validation;

  constructor(
    {
      name,
      price,
      date,
      type,
    }: {
      name: string;
      price: number;
      date: string;
      type: T;
    } = {
      name: '',
      price: 0,
      date: '',
      type: {} as T,
    },
  ) {
    this.name = name;
    this.price = price;
    this.date = date;
    this.type = type;

    this.validation = new Validation({
      name: 'required',
      price: 'required',
      date: 'required',
      type: 'required',
    });
  }

  data() {
    return {
      name: this.name,
      date: this.date,
      price: this.price,
      type: this.type,
    };
  }

  get key() {
    return `${Math.random() * Date.now()}`;
  }

  get cost() {
    return this.price;
  }

  get icon() {
    return 'other';
  }

  get IS_PRODUCT() {
    return true;
  }

  get year() {
    const d = parseDate(this.date);
    return d.getUTCFullYear();
  }

  isValid(field: string): boolean {
    return this.validation.isValid(this, field);
  }
}
