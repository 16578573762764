import { createStore } from 'vuex';

import gameService from '@/services/GameService';
import hardwareService from '@/services/HardwareService';
import systemService from '@/services/SystemService';
import storeService from '@/services/StoreService';
import wishlistService from '@/services/WishlistService';
import scheduleService from '@/services/ScheduleService';
import authService, { LoginData } from './services/AuthService';
import { Game } from './models/game.model';
import {
  HardwareComponent,
  HardwareConfiguration,
  HardwarePurchase,
} from './models/hardware.model';
import { Schedule, ScheduleDay } from './models/schedule.model';
import { Notification } from './models/notification.model';
import { WishlistItem } from './models/wishlist.model';
import { PurchaseType } from './models/purchase.model';
import { UUIDUtil } from './core/services/UUIDUtil';
import { DataResponse } from './core/services/BaseService';
import Product from './models/product.model';
import { System } from './models/system.model';
import { Store } from './models/store.model';

export interface SoesahGamingState {
  loggedIn: boolean;
  item: Game | HardwareComponent | null;
  games: Game[];
  hardwareConfiguration: HardwareConfiguration | null;
  hardwareComponents: HardwareComponent[];
  hardwarePurchases: HardwarePurchase[];
  system: System | null;
  systems: System[];
  stores: Store[];
  wishlist: WishlistItem[];
  schedule: Schedule;
  daysLeft: number;
  notifications: Notification[];
  purchaseType: PurchaseType;
  year: number;
  date: string;
  filter: string;
}

interface UpdateGameEvent {
  item: FormData;
  originalItem: Game;
  url: string;
  edit: boolean;
}
interface UpdateHardwareComponentEvent {
  item: HardwareComponent;
  edit: boolean;
}
interface UpdateHardwarePurchaseEvent {
  item: HardwarePurchase;
  edit: boolean;
}
interface UpdateWishlistEvent {
  item: WishlistItem;
  edit: boolean;
}
interface UpdateScheduleEvent {
  item: Schedule;
  edit: boolean;
}
interface UpdateStoreEvent {
  item: Store;
  edit: boolean;
}
interface UpdateSystemEvent {
  item: System;
  edit: boolean;
}

export enum Mutations {
  SetLoggedIn = 'SetLoggedIn',
  SetFilter = 'SetFilter',
  SetViewItem = 'SetViewItem',
  SetGames = 'SetGames',
  SetHardwareConfiguration = 'SetHardwareConfiguration',
  SetHardwareComponents = 'SetHardwareComponents',
  SetHardwarePurchases = 'SetHardwarePurchases',
  SetWishlist = 'SetWishlist',
  SetSystem = 'SetSystem',
  SetSystems = 'SetSystems',
  SetStores = 'SetStores',
  SetSchedule = 'SetSchedule',
  SetDaysLeft = 'SetDaysLeft',
  UpdateDay = 'UpdateDay',
  ToggleExercised = 'ToggleExercised',
  ToggleProgrammed = 'ToggleProgrammed',
  ToggleWrite = 'ToggleWrite',
  ToggleRead = 'ToggleRead',
  SetYear = 'SetYear',
  SetScheduleDate = 'SetScheduleDate',
  Notify = 'Notify',
  Dismiss = 'Dismiss',
  SetPurchaseType = 'SetPurchaseType',
}

export enum Actions {
  GetAuth = 'GetAuth',
  Login = 'Login',
  Logout = 'Logout',
  GetGames = 'GetGames',
  GetGameByName = 'GetGameByName',
  RemoveGameImage = 'RemoveGameImage',
  UpdateGameItem = 'UpdateGameItem',
  GetHardwareConfiguration = 'GetHardwareConfiguration',
  GetHardwareComponents = 'GetHardwareComponents',
  GetHardwarePurchases = 'GetHardwarePurchases',
  GetHardwareComponentByName = 'GetHardwareComponentByName',
  GetHardwarePurchaseByName = 'GetHardwarePurchaseByName',
  UpdateHardwareComponent = 'UpdateHardwareComponent',
  UpdateHardwarePurchase = 'UpdateHardwarePurchase',
  GetPurchases = 'GetPurchases',
  GetWishlist = 'GetWishlist',
  GetWishlistByName = 'GetWishlistByName',
  UpdateWishlistItem = 'UpdateWishlistItem',
  RemoveWishlistItem = 'RemoveWishlistItem',
  GetStores = 'GetStores',
  GetStoreByID = 'GetStoreByID',
  UpdateStore = 'UpdateStore',
  GetSystems = 'GetSystems',
  GetSystemByName = 'GetSystem',
  UpdateSystem = 'UpdateSystem',
  SetScheduleDate = 'SetScheduleDate',
  GetSchedule = 'GetSchedule',
  UpdateSchedule = 'UpdateSchedule',
  Notify = 'Notify',
  Dismiss = 'Dismiss',
}

export const store = createStore<SoesahGamingState>({
  state: {
    loggedIn: false,
    item: null,
    games: [],
    hardwareConfiguration: null,
    hardwareComponents: [],
    hardwarePurchases: [],
    system: null,
    systems: [],
    stores: [],
    schedule: new Schedule(),
    daysLeft: 0,
    notifications: [],
    wishlist: [],
    purchaseType: PurchaseType.Game,
    date: '',
    year: new Date().getFullYear(),
    filter: '',
  },
  mutations: {
    [Mutations.SetLoggedIn](state, loggedIn: boolean) {
      state.loggedIn = loggedIn;
    },
    [Mutations.SetFilter](state, filter: string) {
      state.filter = filter;
    },
    [Mutations.SetViewItem](state, item: Game | HardwareComponent | null) {
      state.item = item;
    },
    [Mutations.SetGames](state, games: Game[]) {
      state.games = games;
    },
    [Mutations.SetHardwareConfiguration](state, config: HardwareConfiguration) {
      state.hardwareConfiguration = config;
    },
    [Mutations.SetHardwareComponents](state, hardware: HardwareComponent[]) {
      state.hardwareComponents = hardware;
    },
    [Mutations.SetHardwarePurchases](state, hardware: HardwarePurchase[]) {
      state.hardwarePurchases = hardware;
    },
    [Mutations.SetWishlist](state, wishlist: WishlistItem[]) {
      state.wishlist = wishlist;
    },
    [Mutations.SetSystem](state, system: System) {
      state.system = system;
    },
    [Mutations.SetSystems](state, systems: System[]) {
      state.systems = systems;
    },
    [Mutations.SetStores](state, stores: Store[]) {
      state.stores = stores;
    },
    [Mutations.SetSchedule](state, schedule: Schedule) {
      state.schedule = schedule;
    },
    [Mutations.SetDaysLeft](state, daysLeft: number) {
      state.daysLeft = daysLeft;
    },
    [Mutations.UpdateDay](state, day: ScheduleDay) {
      state.schedule = state.schedule.updateDay({
        ...day,
        played: !!day.game,
      });
    },
    [Mutations.ToggleExercised](state, day: ScheduleDay) {
      state.schedule = state.schedule.updateDay({
        ...day,
        exercised: !day.exercised,
        programmed: false,
        read: false,
        write: false,
      });
    },
    [Mutations.ToggleProgrammed](state, day: ScheduleDay) {
      state.schedule = state.schedule.updateDay({
        ...day,
        programmed: !day.programmed,
        exercised: false,
        read: false,
        write: false,
      });
    },
    [Mutations.ToggleWrite](state, day: ScheduleDay) {
      state.schedule = state.schedule.updateDay({
        ...day,
        programmed: false,
        exercised: false,
        read: false,
        write: !day.write,
      });
    },
    [Mutations.ToggleRead](state, day: ScheduleDay) {
      state.schedule = state.schedule.updateDay({
        ...day,
        programmed: false,
        exercised: false,
        read: !day.read,
        write: false,
      });
    },
    [Mutations.SetYear](state, year: number) {
      state.year = year;
    },
    [Mutations.SetScheduleDate](state, date: string) {
      state.date = date;
    },
    [Mutations.Notify](state, notification: Notification) {
      state.notifications = [...state.notifications, notification];
    },
    [Mutations.Dismiss](state, uuid) {
      state.notifications = [
        ...state.notifications.filter((n) => n.uuid !== uuid),
      ];
    },
    [Mutations.SetPurchaseType](state, type: PurchaseType) {
      state.purchaseType = type;
    },
  },
  actions: {
    async [Actions.GetAuth]({ commit }) {
      const response = await authService.update();
      commit(Mutations.SetLoggedIn, response.status);
    },
    async [Actions.Login]({ commit }, data: LoginData) {
      const response = await authService.login(data);
      commit(Mutations.SetLoggedIn, response.status);
      return response;
    },
    async [Actions.Logout]({ commit }): Promise<boolean> {
      const status = await authService.logout();
      if (status) {
        commit(Mutations.SetLoggedIn, false);
      }
      return status;
    },
    async [Actions.GetGames]({ state, commit }) {
      if (!state.games.length) {
        const response = await gameService.load();
        commit(Mutations.SetGames, response.data);
      }
    },
    async [Actions.GetGameByName]({ commit, dispatch, state }, name) {
      await dispatch(Actions.GetGames);
      const game = state.games.find((item) => item.url === name);
      commit(Mutations.SetViewItem, game);
      return game;
    },
    async [Actions.RemoveGameImage](_, filename) {
      return await gameService.removeGameImage(filename);
    },
    async [Actions.UpdateGameItem](
      { commit, state, dispatch },
      evt: UpdateGameEvent,
    ) {
      dispatch(Actions.Notify, {
        type: 'info',
        text: 'Saving...',
      });

      let response: DataResponse<Game[]>;

      if (evt.edit) {
        response = await gameService.update(evt.url, evt.item);
      } else {
        response = await gameService.save(evt.item);
      }

      if (response.status) {
        commit(Mutations.SetGames, response.data);
        dispatch(Actions.Notify, {
          type: 'success',
          text: `${evt.edit ? 'Updated' : 'Added'} ${
            evt.originalItem.name
          } successfully`,
        });
      } else {
        dispatch(Actions.Notify, {
          type: 'error',
          text: response.message,
        });
      }
      return response;
    },
    async [Actions.GetHardwareConfiguration]({ state, commit }) {
      if (!state.hardwareConfiguration) {
        const response = await hardwareService.loadConfiguration();
        commit(Mutations.SetHardwareConfiguration, response.data);
      }
    },
    async [Actions.GetHardwareComponents]({ state, commit }) {
      if (!state.hardwareComponents.length) {
        const response = await hardwareService.loadComponents();
        commit(Mutations.SetHardwareComponents, response.data);
      }
    },
    async [Actions.GetHardwarePurchases]({ state, commit }) {
      if (!state.hardwarePurchases.length) {
        const response = await hardwareService.loadPurchases();
        commit(Mutations.SetHardwarePurchases, response.data);
      }
    },
    async [Actions.GetHardwareComponentByName](
      { commit, dispatch, state },
      name,
    ) {
      await dispatch(Actions.GetHardwareComponents);
      const item = state.hardwareComponents.find((item) => item.url === name);
      commit(Mutations.SetViewItem, item);
      return item;
    },
    async [Actions.GetHardwarePurchaseByName](
      { commit, dispatch, state },
      name,
    ) {
      await dispatch(Actions.GetHardwarePurchases);
      const item = state.hardwarePurchases.find((item) => item.url === name);
      commit(Mutations.SetViewItem, item);
      return item;
    },
    async [Actions.UpdateHardwareComponent](
      { commit, state, dispatch },
      evt: UpdateHardwareComponentEvent,
    ) {
      dispatch(Actions.Notify, {
        type: 'info',
        text: 'Saving...',
      });

      const response = evt.edit
        ? await hardwareService.updateComponent(evt.item)
        : await hardwareService.saveComponent(evt.item);
      if (response.status) {
        let hardware = state.hardwareComponents;
        const index = hardware.findIndex((w) => w.id === evt.item.id);
        if (index !== -1) {
          hardware.splice(index, 1, evt.item);
        }
        hardware = evt.edit
          ? hardware
          : [...hardware, new HardwareComponent(response.data)];

        commit(Mutations.SetHardwareComponents, hardware);
        dispatch(Actions.Notify, {
          type: 'success',
          text: `${evt.edit ? 'Updated' : 'Added'} ${
            evt.item.name
          } successfully`,
        });
      } else {
        dispatch(Actions.Notify, {
          type: 'error',
          text: response.message,
        });
      }
      return response;
    },
    async [Actions.UpdateHardwarePurchase](
      { commit, state, dispatch },
      evt: UpdateHardwarePurchaseEvent,
    ) {
      dispatch(Actions.Notify, {
        type: 'info',
        text: 'Saving...',
      });

      const response = evt.edit
        ? await hardwareService.updatePurchase(evt.item)
        : await hardwareService.savePurchase(evt.item);
      if (response.status) {
        let purchases = state.hardwarePurchases;
        const index = purchases.findIndex((w) => w.id === evt.item.id);
        if (index !== -1) {
          purchases.splice(index, 1, evt.item);
        }
        purchases = evt.edit
          ? purchases
          : [...purchases, new HardwarePurchase(response.data)];

        commit(Mutations.SetHardwarePurchases, purchases);
        dispatch(Actions.Notify, {
          type: 'success',
          text: `${evt.edit ? 'Updated' : 'Added'} ${
            evt.item.name
          } successfully`,
        });
      } else {
        dispatch(Actions.Notify, {
          type: 'error',
          text: response.message,
        });
      }
      return response;
    },
    async [Actions.GetPurchases]({ dispatch }) {
      await dispatch(Actions.GetGames);
      await dispatch(Actions.GetHardwarePurchases);
    },
    async [Actions.GetWishlist]({ state, commit }) {
      if (!state.wishlist.length) {
        const response = await wishlistService.load();
        commit(Mutations.SetWishlist, response.data.list);
      }
    },
    async [Actions.GetWishlistByName]({ commit, dispatch, state }, name) {
      await dispatch(Actions.GetWishlist);
      const item = state.wishlist.find((item) => item.url === name);
      commit(Mutations.SetViewItem, item);
      return item;
    },
    async [Actions.UpdateWishlistItem](
      { commit, state, dispatch },
      evt: UpdateWishlistEvent,
    ) {
      dispatch(Actions.Notify, {
        type: 'info',
        text: 'Saving...',
      });

      let wishlist = state.wishlist;
      const index = wishlist.findIndex((w) => w.link === evt.item.link);
      if (index !== -1) {
        wishlist.splice(index, 1, evt.item);
      }
      wishlist = evt.edit ? wishlist : [...wishlist, evt.item];

      const response = await wishlistService.save(wishlist);
      if (response.status) {
        commit(Mutations.SetWishlist, wishlist);
        dispatch(Actions.Notify, {
          type: 'success',
          text: `${evt.edit ? 'Updated' : 'Added'} ${
            evt.item.name
          } successfully`,
        });
      } else {
        dispatch(Actions.Notify, {
          type: 'error',
          text: response.message,
        });
      }
      return response;
    },
    async [Actions.RemoveWishlistItem](
      { commit, state, dispatch },
      item: WishlistItem,
    ) {
      dispatch(Actions.Notify, {
        type: 'info',
        text: 'Saving...',
      });

      let wishlist: WishlistItem[] = [
        ...state.wishlist.filter((i) => i.name !== item.name),
      ];
      const response = await wishlistService.save(wishlist);
      if (response.status) {
        commit(Mutations.SetWishlist, wishlist);
        dispatch(Actions.Notify, {
          type: 'success',
          text: `Removed ${item.name} successfully`,
        });
      } else {
        dispatch(Actions.Notify, {
          type: 'error',
          text: response.message,
        });
      }
      return response;
    },
    async [Actions.GetSystems]({ commit }) {
      const systems = await systemService.getSystems();

      commit(Mutations.SetSystems, systems);
    },
    async [Actions.GetStores]({ commit }) {
      const { status, data } = await storeService.getList();

      if (status) {
        commit(Mutations.SetStores, data);
      }
    },
    async [Actions.GetStoreByID]({ commit, dispatch, state }, id: number) {
      await dispatch(Actions.GetStores);
      const item = state.stores.find((item) => item.id === id);
      commit(Mutations.SetViewItem, item);
      return item;
    },
    async [Actions.UpdateStore](
      { commit, state, dispatch },
      evt: UpdateStoreEvent,
    ) {
      dispatch(Actions.Notify, {
        type: 'info',
        text: 'Saving...',
      });
      const store = evt.item;
      const { status, data, message } =
        store.id !== -1
          ? await storeService.update(store)
          : await storeService.add(store);
      if (status) {
        dispatch(Actions.Notify, {
          type: 'success',
          text: `${evt.edit ? 'Updated' : 'Added'} ${
            evt.item.name
          } successfully`,
        });
        const stores = evt.edit
          ? [...state.stores.filter((d) => d.id !== store.id), data]
          : [...state.stores, data];
        commit(Mutations.SetStores, stores);
      } else {
        dispatch(Actions.Notify, {
          type: 'error',
          text: message,
        });
      }
      return data;
    },
    async [Actions.GetSystemByName]({ state, dispatch, commit }, slug) {
      await dispatch(Actions.GetSystems);
      const system = state.systems.find((item) => item.slug === slug);
      commit(Mutations.SetSystem, system);
      return system;
    },
    async [Actions.UpdateSystem](
      { dispatch, state, commit },
      evt: UpdateSystemEvent,
    ) {
      dispatch(Actions.Notify, {
        type: 'info',
        text: 'Saving...',
      });
      const system = evt.item;
      const { status, data, message } =
        system.id !== -1
          ? await systemService.update(system)
          : await systemService.save(system);
      if (status) {
        dispatch(Actions.Notify, {
          type: 'success',
          text: `${evt.edit ? 'Updated' : 'Added'} ${
            evt.item.name
          } successfully`,
        });
        const systems = evt.edit
          ? [...state.systems.filter((d) => d.id !== system.id), data]
          : [...state.systems, data];
        commit(Mutations.SetSystems, systems);
      } else {
        dispatch(Actions.Notify, {
          type: 'error',
          text: message,
        });
      }
      return data;
    },
    async [Actions.SetScheduleDate]({ commit, dispatch }, date) {
      commit(Mutations.SetScheduleDate, date);
      dispatch(Actions.GetSchedule);
    },
    async [Actions.GetSchedule]({ state, commit }) {
      const response = await scheduleService.load(state.date);
      commit(Mutations.SetSchedule, response.data);
      commit(Mutations.SetDaysLeft, response.data.daysLeft);
    },
    async [Actions.UpdateSchedule](
      { commit, dispatch },
      evt: UpdateScheduleEvent,
    ) {
      dispatch(Actions.Notify, {
        type: 'info',
        text: 'Saving...',
      });

      const schedule = evt.item;
      const response = await scheduleService.save(schedule);
      if (response.status) {
        commit(Mutations.SetSchedule, response.data);
        dispatch(Actions.Notify, {
          type: 'success',
          text: 'Updated schedule successfully',
        });
      } else {
        dispatch(Actions.Notify, {
          type: 'error',
          text: response.message,
        });
      }
      return response;
    },
    [Actions.Notify]({ commit, dispatch }, notification: Notification) {
      const uuid = UUIDUtil.uuid4();
      commit(Mutations.Notify, { ...notification, uuid });
      const delays: { [index: string]: number } = {
        info: 2000,
        success: 2500,
        warning: 3500,
        error: 5500,
      };
      dispatch(Actions.Dismiss, {
        delay: delays[notification.type],
        uuid: uuid,
      });
    },
    [Actions.Dismiss]({ commit }, data) {
      window.setTimeout(() => {
        commit(Mutations.Dismiss, data.uuid);
      }, data.delay);
    },
  },
  getters: {
    filteredGames: (state) => {
      return state.games
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .filter(
          (g: Game) =>
            ~g.name.toLowerCase().indexOf(state.filter.toLowerCase()),
        );
    },
    filteredHardware: (state) => {
      return state.hardwareComponents
        .sort((a, b) => (a.fullName < b.fullName ? -1 : 1))
        .filter(
          (h: HardwareComponent) =>
            ~`${h.fullName} ${h.type}`
              .toLowerCase()
              .indexOf(state.filter.toLowerCase()),
        );
    },
    filteredPurchases: (state) => {
      const gamesAndDLC = state.games.reduce(
        (a: (Game | Product<any>)[], g) => [
          ...a,
          g,
          ...g.purchases.map((p: any) => {
            return new Product<any>({
              ...p,
              name: `${g.name} - ${p.name}`,
            });
          }),
        ],
        [],
      );
      const purchases =
        state.purchaseType === PurchaseType.Hardware
          ? [...state.hardwarePurchases]
          : state.purchaseType === PurchaseType.All
          ? [...gamesAndDLC, ...state.hardwarePurchases]
          : [...gamesAndDLC];

      return purchases
        .sort((a, b) => (a.date > b.date ? 1 : -1))
        .filter((item) => item.year === state.year);
    },
    purchaseTotal: (_, getters): number => {
      return getters.filteredPurchases.reduce(
        (total: number, item: Game | HardwarePurchase) => total + item.price,
        0,
      );
    },
  },
});
