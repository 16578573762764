import Validation from '@/core/services/Validation';

export enum WishlistItemType {
  Game,
  Hardware,
}

export interface WishlistItemData {
  name: string;
  link: string;
  release: string;
  type: WishlistItemType;
}

export class WishlistItem {
  public name: string;
  public link: string;
  public release: string;
  public type: WishlistItemType;
  public validation: Validation;

  constructor(
    { name, link, release, type } = {
      name: '',
      link: '',
      release: '',
      type: WishlistItemType.Game,
    },
  ) {
    this.name = name;
    this.link = link;
    this.release = release;
    this.type = type;

    this.validation = new Validation({
      name: 'required',
      link: 'required',
    });
  }

  get url(): string {
    return `${this.name.replace(/\W+/g, '-').replace('--', '').toLowerCase()}`;
  }

  public data(): WishlistItemData {
    return {
      name: this.name,
      link: this.link,
      release: this.release,
      type: this.type,
    };
  }

  isValid(field?: string): boolean {
    return this.validation.isValid(this, field);
  }
}

export class Wishlist {
  public list: WishlistItem[];

  constructor(list: WishlistItem[]) {
    this.list = list;
  }
}
