import axios, { AxiosStatic, AxiosResponse } from 'axios';

export const STATUS_OK = 200;

export interface SuccessResponse {
  status: boolean;
  message: string;
}

export interface DataResponse<T> {
  status: boolean;
  message: string;
  data: T;
}

export class BaseService {
  // set up axios proxy to allow requests
  public $http: AxiosStatic = axios;
  // set up Vue as an eventing proxy
  // public event: Vue = new Vue();

  public parseResponse(response: AxiosResponse): {
    status: boolean;
    message: string;
  } {
    return {
      message: response.data.message
        ? response.data.message
        : response.statusText,
      status: response.status === STATUS_OK,
    };
  }
}
