import { Schedule } from '@/models/schedule.model';
import { BaseService, DataResponse } from '@/core/services/BaseService';

class ScheduleService extends BaseService {
  public data!: Schedule;
  private url: string = '/api/schedule';

  constructor() {
    super();
  }

  public async load(date: string): Promise<DataResponse<Schedule>> {
    const response = await this.$http
      .get(`${this.url}/${date}`)
      .catch((error) => error.response);

    const { status, message } = this.parseResponse(response);

    if (status) {
      this.data = new Schedule(response.data.data);
    }

    return {
      status,
      message,
      data: this.data,
    };
  }

  public async save(schedule: Schedule): Promise<DataResponse<Schedule>> {
    const response = await this.$http
      .post(`${this.url}/${schedule.date}`, schedule.data())
      .catch((error) => error.response);

    const { status, message } = this.parseResponse(response);

    return {
      status,
      message,
      data: schedule,
    };
  }
}

export default new ScheduleService();
