import { BaseService, DataResponse } from '@/core/services/BaseService';
import { System } from '@/models/system.model';
import { AxiosResponse } from 'axios';

class SystemService extends BaseService {
  public items: System[] = [];
  private url: string = '/api/system';
  constructor() {
    super();
  }

  public async getSystems(): Promise<System[]> {
    const response: AxiosResponse<DataResponse<System[]>> = await this.$http
      .get(this.url)
      .catch((error) => error.response);

    return response.data.data.map((s: System) => new System(s));
  }

  public async save(system: System): Promise<DataResponse<System>> {
    const response: AxiosResponse<DataResponse<System>> = await this.$http
      .post<System>(`${this.url}`, system)
      .catch((error) => error.response);

    const { status, message } = this.parseResponse(response);
    return {
      status,
      message,
      data: response.data.data,
    };
  }

  public async update(system: System): Promise<DataResponse<System>> {
    const response: AxiosResponse<DataResponse<System>> = await this.$http
      .put<System>(`${this.url}/${system.id}`, system)
      .catch((error) => error.response);

    const { status, message } = this.parseResponse(response);

    return {
      status,
      message,
      data: response.data.data,
    };
  }
}

export default new SystemService();
